<template>
  <div class="home">
    <component :is="TableList" ref="basePage" @approve='openDrawer'/>
    <SubModal
      :modalConfig.sync="modalConfig"
      :visible.sync="modalConfig.visible"
      style="z-index: 2000"
      @onClose="closeModal"
    >
      <component
        :is="formName"
        ref="modalForm"
        @submit="onSubmit"
        @onClose="closeModal"
        :formConfig="formConfig"
        :defaultValue="defaultValue"
      />
    </SubModal>
  </div>
</template>

<script>
import SubModal from '../../components/modal';
import Approve from '../approve';

export default {
  name: 'TablePageApproveBase',
  data() {
    return {
      TableList: 'Table',
      modalConfig: {
        width: '600px',
        title: '提交审批',
        type: 'Full',
        visible: false,
      },
      formConfig: {},
      formName: 'Approve',
      defaultValue: {},
    };
  },
  components: {
    SubModal,
    Approve,
  },
  // watch: {
  //   $route(to, from) {
  //     this.closeModal();
  //   },
  // },
  methods: {
    // 保存提交审批
    openDrawer(params) {
      this.formConfig = params;
      this.modalConfig.visible = true;

      if (!this.$refs.basePage.modalConfig.visible) {
        this.defaultValue = {};
      }
    },
    // 关闭弹框
    closeModal() {
      const formData = this.$refs.modalForm.fApi.formData();

      if (formData) {
        this.defaultValue = formData;
      }

      this.modalConfig.visible = false;
    },
    // 保存
    onSubmit() {
      this.defaultValue = {};
      this.$refs.basePage.closeModal();
      this.$refs.basePage.getList();
      this.modalConfig.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>

</style>
