var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(_vm.TableList, {
        ref: "basePage",
        tag: "component",
        on: { approve: _vm.openDrawer },
      }),
      _c(
        "SubModal",
        {
          staticStyle: { "z-index": "2000" },
          attrs: {
            modalConfig: _vm.modalConfig,
            visible: _vm.modalConfig.visible,
          },
          on: {
            "update:modalConfig": function ($event) {
              _vm.modalConfig = $event
            },
            "update:modal-config": function ($event) {
              _vm.modalConfig = $event
            },
            "update:visible": function ($event) {
              return _vm.$set(_vm.modalConfig, "visible", $event)
            },
            onClose: _vm.closeModal,
          },
        },
        [
          _c(_vm.formName, {
            ref: "modalForm",
            tag: "component",
            attrs: {
              formConfig: _vm.formConfig,
              defaultValue: _vm.defaultValue,
            },
            on: { submit: _vm.onSubmit, onClose: _vm.closeModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }