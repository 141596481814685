import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
import { SelectDialog, PrepaymentDetail } from '../../../../components';

formCreate.component('SelectDialog', SelectDialog);
formCreate.component('PrepaymentDetail', PrepaymentDetail);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20201126000000096',
      formFunctionCode: 'tpm_customer_prepayment_form',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;

      if (v.field === 'customerCode') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'tpm_customer_list',
            data: [],
            idKey: 'customerCode',
            paramData: {},
          },
        };
      } else if (v.field === 'detailVos') {
        v.props = {
          ...v.props,
          getValue: (field) => this.getFieldValue(field),
          parmas: {
            functionCode: 'tpm_detail_config_list',
            parentCode: 'CRM20201126000000096',
          },
          type: 'customerCode',
        };
      }

      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const customerCode = this.getRule('customerCode');
      customerCode.on.change = (e) => {
        this.setValue({
          customerCode: e ? e[0].customerCode : '',
          customerName: e ? e[0].customerName : '',
          customerOrgCode: e ? e[0].orgCode : '',
          customerOrgName: e ? e[0].orgName : '',
          detailVos: [],
        });
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmActAdvancePayController/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;

              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit(e) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmActAdvancePayController/save';
        const params = { ...formData };

        params.saveType = e.toString();
        params.advanceType = 'customer';

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmActAdvancePayController/update';
          params.id = this.formConfig.row.id;
          params.saveType = (e + 2).toString();
        }

        if (e === 2) {
          this.$emit('submit', {
            row: params,
            submitUrl: url,
          });
        } else {
          request.post(url, params).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      }
    },
  },
};
